<template>
  <div>
    <add-expense></add-expense>
  </div>
</template>

<script>
import AddExpense from './AddPurchaseOrder.vue';

export default {
  components: {
    AddExpense,
  },

  data() {
    return {};
  },
};
</script>
